import React from 'react'
import { PageNotFoundContainer } from './assets/PageNotFoundContainer.style'
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';

const AuthNotFound = ({t}) => {
  return (
    <PageNotFoundContainer>
        <div className='header-container'>
        <img className='header-img' src='/assets/img/401.jpg' alt={t("401authMsg")}/>
        <div className='header-text'>{t("AuthorizationErrText")}</div>
        </div>
        <div className='description'>
            {t("notAuthorizedText")}
        </div>
        <NavLink to={"/"}>
        <Button label={t("goToHomePage")} className='backToHomeBtn'/>
        </NavLink>
    </PageNotFoundContainer>
  )
}

export default withTranslation()(AuthNotFound);