import {
  OrganisationsContainer,
  OrganisationAddContainer,
  CreateOrganisationDialogContainer,
  ViewOrganisationDialogContainer,
} from "./assets/OrganisationsContainer.style";
import { CreateUserDialogContainer } from "./assets/UsersContainer.style";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import WaitDialog from "../components/WaitDialog";
import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce  } from "react-toastify";
import { format } from "date-fns";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import { orgsList, usersList, countryCodes,statusTypesList } from "../data/test-data";
import { isCTAdmin } from "../functionalities/common/token-check";

const emptyOrganisationData = {
  name: "",
  email: "",
  roles: [],
  web: "",
  address: "",
  city: "",
  country: "",
};

const emptyAssignData = {
  organisationName: [],
  assignees: [],
};

const emptyUserData = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
};

const Organisations = ({ t }) => {
  const [organisations, setOrganisations] = useState([]);
  const [singularOrg, setSingularOrg] = useState({});
  const [loading, setLoading] = useState(false);
  const [createOrganisationVisible, setCreateOrganisationVisible] =
    useState(false);
  const [assignUserToOrganisationVisible, setAssignUserToOrganisationVisible] =
    useState(false);
  const [orgData, setOrgData] = useState(emptyOrganisationData);
  const [assigneeData, setAssigneeData] = useState(emptyAssignData);
  const [roles, setRoles] = useState([]);
  const [addUserToOrganisationVisible, setAddUserToOrganisationVisible] =
    useState(false);
  const [removeUserFromOrganisationVisible, setRemoveUserFromOrganisationVisible] =
    useState(false);
  const [assignUserToOrgOid, setAssignUserToOrgOid] = useState();
  const [editOrganisationVisible, setEditOrganisationVisible] = useState(false);
  const [viewOrganisationVisible, setViewOrganisationVisible] = useState(false);
  const [userData, setUserData] = useState(emptyUserData);
  const [orgRoles, setOrgRoles] = useState([]);
  const [usersOfOrg, setUsersOfOrg] = useState([]);
  const [tbRemoved, setTbRemoved] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const countriesWithCode = [
    { name: "Afghanistan", code: "AF" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bonaire", code: "BQ" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: 'Cote D"Ivoire', code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Curacao", code: "CW" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Eswatini", code: "SZ" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Ivory Coast", code: "CI" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: 'Korea, Democratic People"S Republic of', code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: 'Lao People"S Democratic Republic', code: "LA" },
    { name: "Laos", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "North Macedonia", code: "MK" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "RWANDA", code: "RW" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "Saint Barthelemy", code: "BL" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Martin", code: "MF" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Sint Maarten", code: "SX" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "South Sudan", code: "SS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Vietnam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
    { name: "Åland Islands", code: "AX" },
  ];

  const handleOrgCreation = (field, value) => {
    setOrgData({ ...orgData, [field]: value });
  };

  const handleAssignees = (field, value) => {
    setAssigneeData({ ...assigneeData, [field]: value });
  };

  const handleRoles = (value) => {
  
    setOrgData({
      ...orgData,
      ["roleList"]: emptyOrganisationData["roleList"].push(value),
    });
   
  };

  ///////////////////////////////////////////////////////////

  const submitOrgCreation = () => {
    setLoading(true);

    if (
      orgData.name === "" ||
      orgData.email === "" ||
      orgData.country === "" ||
      orgData.city === "" ||
      orgData.address === "" ||
      orgData.web === "" ||
      orgData.roleList === 0
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(orgData.email)) {
      toast.warning(<Trans i18nKey="warnEmailFormat" />);
      setLoading(false);
      return;
    }

    const organisationData = {
      organisationDTO: {
        ...orgData,
      },
    };

    SysService.addOrganisation(organisationData).then(
      (response) => {
        if (response.status === 200) {
          // toast.success(<Trans i18nKey={response.data.responseCode} />);
          toast.success(<Trans i18nKey={"successOrganisationCreation"} />);
          SysService.getOrganisations().then(  
            (response) => {
              setOrganisations(response.data);
              setLoading(false);
              setCreateOrganisationVisible(false);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.responseCode) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();

              toast.error(<Trans i18nKey={errorMessage} />);
              setLoading(false);
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(errorMessage,<Trans i18nKey={"failOrganisationCreation"} />);
        setLoading(false);
      }
    );
  };

  const submitUserCreation = () => {
    setLoading(true);

    if (
      userData.email === "" ||
      userData.firstName === "" ||
      userData.lastName === "" ||
      assignUserToOrgOid === "" ||
      assignUserToOrgOid === null ||
      assignUserToOrgOid === undefined
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.email)) {
      toast.warning(<Trans i18nKey="warnEmailFormat" />);
      setLoading(false);
      return;
    }

    const ctUserDTO = {
      ctUserDTO: {
        email: userData.email,
        firstname: userData.firstName,
        lastname: userData.lastName,
        organisation: { id: assignUserToOrgOid },
      },
    };

    SysService.addUser(ctUserDTO).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={response.data.responseCode} />);
        }
        setLoading(false);
        setAddUserToOrganisationVisible(false);
        setUserData(emptyUserData);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
     
        toast.error(<Trans i18nKey={error.response.data.responseMessage} />);
        setLoading(false);
      }
    );
  };

  const submitOrgEdit = () => {
    setLoading(true);

    if (
      singularOrg.name === "" ||
      singularOrg.email === "" ||
      singularOrg.country === "" ||
      singularOrg.city === "" ||
      singularOrg.address === "" ||
      singularOrg.web === "" ||
      singularOrg.roleList === 0
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(singularOrg.email)) {
      toast.warning(<Trans i18nKey="warnEmailFormat" />);
      setLoading(false);
      return;
    }

    const organisationData = {
      organisationDTO: {
        ...singularOrg,
      },
    };
   
    const {lastModificationTime, registrationTime,...trim} =  organisationData.organisationDTO
    const organisationDataTrim = {
      organisationDTO: {
        ...trim,
      },
    };
   
    return
    SysService.updateOrganisation(organisationDataTrim).then(
      (response) => {
        if (response.status === 200) {
          toast.success(<Trans i18nKey={"successOrganisationUpdate"} />);
          SysService.getOrganisations().then(
            (response) => {
              setOrganisations(response.data);
              setLoading(false);
              setEditOrganisationVisible(false);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.responseCode) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();

              toast.error(<Trans i18nKey={errorMessage} />);
              setLoading(false);
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        
        toast.error(errorMessage,<Trans i18nKey={"failOrganisationUpdate"} />);
        setLoading(false);
      }
    );
  };

  ///////////////////////////////////////////////////////////

  const submitUserRemoval = () => {
    setLoading(true);

    if(tbRemoved["user"].length === 0){
      setLoading(false);
      setRemoveUserFromOrganisationVisible(false);
      return;
    }
    else{

      for(let i= 0; i<tbRemoved["user"].length; i++){
      
        SysService.deleteUserById(tbRemoved["user"][i]).then(
          (response) => {
            if (response.status === 200) {
              // toast.success(<Trans i18nKey={response.data.responseCode} />);
             
            }
          },
          (error) => {
            const errorMessage =
              (error.response &&
                error.response.data &&
                error.response.data.responseCode) ||
              error.message ||
              error.timeoutErrorMessage ||
              error.toString();
            
            // toast.error(<Trans i18nKey={errorMessage} />);
            toast.error(errorMessage,<Trans i18nKey={"failUserDeletion"} />);
            setLoading(false);
            return;
          }
        );
      }
      if(tbRemoved["user"].length >1){
        setLoading(false);
        toast.success(<Trans i18nKey={"successMultipleUserDeletion"} />)
        setTbRemoved([])
        setRemoveUserFromOrganisationVisible(false);
        return;
      }
      else{
        setLoading(false);
        toast.success(<Trans i18nKey={"successSingleUserDeletion"} />)
        setTbRemoved([])
        setRemoveUserFromOrganisationVisible(false);
        return;
      }
    }


  };

  const registrationTimeTemplate = (data) => {
   
    if (
      data?.registrationTime !== undefined &&
      data?.registrationTime !== null &&
      data?.registrationTime !== ""
    ) {
      const d = new Date(data?.registrationTime);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  const lastmodTimeTemplate = (data) => {
    if (
      data?.lastModificationTime !== undefined &&
      data?.lastModificationTime !== null &&
      data?.lastModificationTime !== ""
    ) {
      const d = new Date(data?.registrationTime);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  const approveRejectTimeTemplate = (data) => {
    if (
      data?.approvalrejectionTime !== undefined &&
      data?.approvalrejectionTime !== null &&
      data?.approvalrejectionTime !== ""
    ) {
      const d = new Date(data?.approvalrejectionTime);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  const statusInfo = (statData) => {
    return t("STATUS_" + statData);
  }
  const actionTemplateStatus = (data) => {
    return (
      <Chip
        label={t("STATUS_" + data.status)}
        className="mr-2 mb-2"
        style={{ backgroundColor: "#382c7c", color: "#FFFFFF" }}
      />
    );
  };

  const actionTemplate = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-exclamation-circle"
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setViewOrganisationVisible(true);
            getOrgInfo(data.id);
          }}
          id="buttonFontWeight"
        />

        <Button
          icon="pi pi-pencil"
          tooltip={t("edit")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setEditOrganisationVisible(true);
            getOrgInfo(data.id);
          }}
          id="buttonFontWeight"
        />

        <Button
          icon="pi pi-user-plus"
          tooltip={t("addUserToOrg")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setAssignUserToOrgOid(data.id);
            getOrgRoles(data.id);
            setAddUserToOrganisationVisible(true);
          }}
          id="buttonFontWeight"
        />

        <Button
          icon="pi pi-user-minus"
          tooltip={t("removeUserFromOrg")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            setRemoveUserFromOrganisationVisible(true);
            getUsersOfOrg(data.id);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const getOrgRoles = (id) => {
    SysService.getOrganisationById(id).then(
      (response) => {
     
        setOrgRoles(response.data.roles);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

     
      }
    );
  };

  const getOrgInfo = (id) => {
    SysService.getOrganisationById(id).then(
      (response) => {
     
        setSingularOrg(response.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
     
      }
    );
  };

  const getAndSetOrgInfo = (id, org) => {
    SysService.getOrganisationById(id).then(
      (response) => {
    
        org = {
          ...org,
          email: response.data.email,
          web: response.data.web,
          address: response.data.address,
          registrationTime: response.data.registrationTime,
          lastModificationTime: response.data.lastModificationTime,
          roles: response.data.roles,
        };
      
        // setSingularOrg(response.data)
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

      
      }
    );
  };

  const getUsersOfOrg = (id) => {
    SysService.getUsersByOrganisationId(id).then(
      (response) => {
      
        setUsersOfOrg(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

       
      }
    );
  };

  const getCountyNameFromCode = (code) => {
    // if( code !== "" ||code !== undefined || code !== null){

    const codeCaps = code?.toUpperCase();
    for (let i = 0; i < countryCodes.length; i++) {
      if (countryCodes[i]["code"] === codeCaps) {
        return countryCodes[i]["name"];
      }
    }
    // }
  };

  useEffect(() => {
    if (isCTAdmin()) {
      SysService.getOrganisations().then(
        (response) => {
          setOrganisations(response.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(<Trans i18nKey={errorMessage} />);
          setLoading(false);
        }
      );

      SysService.getRoles().then(
        (response) => {
          setRoles(response.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.responseCode) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(<Trans i18nKey={errorMessage} />);
          setLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    const updatedSelectedUsers = tbRemoved?.user?.map((userId) =>
      usersOfOrg.find((user) => user.id === userId)
    );
    setSelectedUsers(updatedSelectedUsers);
  }, [tbRemoved.user, usersOfOrg]);

  const handleEditDialogRoleChange = (e) => {
    const selectedRoles = e.value.map((selectedRole) =>
      roles.find((role) => role.name === selectedRole.name)
    );
    setSingularOrg({
      ...singularOrg,
      roles: selectedRoles,
    });
  };

 
  return (
    <OrganisationsContainer>
      <h2>{t("organisationPage")}</h2>

      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createAnOrganisation")}
          onClick={() => {
            setOrgData(emptyOrganisationData);
            setCreateOrganisationVisible(true);
          }}
        />
      </OrganisationAddContainer>

      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={organisations}
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noOrgsFound")}
              paginator
              rows={10}
              loading={loading}
              globalFilterFields={["orgname", "email", "status"]}
            >
              <Column field="name" header={t("organisationName")}></Column>
              <Column field="country" header={t("country")}></Column>
              <Column field="city" header={t("city")}></Column>
              <Column body={actionTemplateStatus} header={t("status")}></Column>
              <Column
                header={t("action")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </BlockUI>

      <Dialog
        visible={createOrganisationVisible}
        modal
        header={t("createAnOrganisation")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateOrganisationVisible(false);
          setOrgData(emptyOrganisationData);
        }}
      >
        <CreateOrganisationDialogContainer>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3">
              <div className="p-float-label input-container">
                <InputText
                  id="organisationName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={orgData?.name}
                  onChange={(e) =>
                    setOrgData({ ...orgData, name: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="organisationName">
                  {t("pleaseEnterOrganisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="email"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={orgData.email}
                  onChange={(e) => handleOrgCreation("email", e.target.value)}
                />
                <label className="platformColor-lbl" htmlFor="email">
                  {t("pleaseEnterOrganisationEmail")} (*)
                </label>
              </div>

              <div className="p-float-label input-container">
                <Dropdown
                  id="countryList"
                  className="p-inputtext-sm w-full view-container"
                  value={orgData?.country}
                  onChange={(e) => setOrgData({ ...orgData, country: e.value })}
                  options={countriesWithCode}
                  optionLabel="name"
                  optionValue="code"
                />
                <label className="platformColor-lbl" htmlFor="city">
                  {t("pleaseEnterCountry")} (*)
                </label>
              </div>

              <div className="p-float-label input-container">
                <InputText
                  id="city"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={orgData?.city}
                  onChange={(e) =>
                    setOrgData({ ...orgData, city: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="city">
                  {t("pleaseEnterCity")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="address"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={orgData?.address}
                  onChange={(e) =>
                    setOrgData({ ...orgData, address: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="address">
                  {t("pleaseEnterAddress")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="web"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={orgData?.web}
                  onChange={(e) =>
                    setOrgData({ ...orgData, web: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="web">
                  {t("pleaseEnterWebpage")} (*)
                </label>
              </div>
              <div className="p-float-label w-full">
                <MultiSelect
                  id="roleList"
                  className="w-full p-inputtext-sm input-container"
                  optionLabel="name"
                  value={orgData.roles}
                  options={roles}
                  display="chip"
                  onChange={(e) =>
                    setOrgData({
                      ...orgData,
                      roles: e.value,
                    })
                  }
                />
                <label className="platformColor-lbl" htmlFor="roleList">
                  {t("pleaseSelectOrganisationRole")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitOrgCreation}
          />
        </CreateOrganisationDialogContainer>
      </Dialog>

      <Dialog
        visible={editOrganisationVisible}
        modal
        header={t("editOrganisationInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setEditOrganisationVisible(false);
          setSingularOrg({});
        }}
      >
        <CreateOrganisationDialogContainer>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="organisationName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularOrg?.name}
                  onChange={(e) =>
                    setSingularOrg({ ...singularOrg, name: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="organisationName">
                  {t("organisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="email"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularOrg?.email}
                  onChange={(e) =>
                    setSingularOrg({ ...singularOrg, email: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="email">
                  {t("organisationEmail")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <Dropdown
                  id="countryList"
                  className="p-inputtext-sm w-full view-container"
                  value={singularOrg?.country}
                  onChange={(e) =>
                    setSingularOrg({ ...singularOrg, country: e.value })
                  }
                  options={countriesWithCode}
                  optionLabel="name"
                  optionValue="code"
                />
                <label className="platformColor-lbl" htmlFor="city">
                  {t("pleaseEnterCountry")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="city"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularOrg?.city}
                  onChange={(e) =>
                    setSingularOrg({ ...singularOrg, city: e.target.value })
                  }
                />
                <label
                  className="platformColor-lbl"
                  htmlFor="lastModificationTime"
                >
                  {t("organisationCity")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="address"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularOrg?.address}
                  onChange={(e) =>
                    setSingularOrg({ ...singularOrg, address: e.target.value })
                  }
                />
                <label
                  className="platformColor-lbl"
                  htmlFor="lastModificationTime"
                >
                  {t("organisationAddress")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="web"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularOrg?.web}
                  onChange={(e) =>
                    setSingularOrg({ ...singularOrg, web: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="email">
                  {t("organisationWeb")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <Dropdown
                  id="organisationStatus"
                  className="w-full p-inputtext-sm"
                  optionLabel="statusTypeName"
                  optionValue="statusTypeCode"
                  placeholder={statusInfo(singularOrg?.status)}
                  value={singularOrg?.status}
                  options={statusTypesList}
                  // editable
                  onChange={
                    (e) => setSingularOrg({...singularOrg, status:e.target.value})
                  }
                />
        
                <label className="platformColor" htmlFor="organisationStatus">
                  {t("organisationStatus")} (*)
                </label>
              </div>
              <div className="p-float-label w-full">
                <MultiSelect
                  id="roleList"
                  className="w-full p-inputtext-sm input-container"
                  optionLabel="name"
                  value={singularOrg?.roles?.map((role) =>
                    roles.find((r) => r.name === role.name)
                  )}
                  options={roles}
                  display="chip"
                  onChange={handleEditDialogRoleChange}
                />
                <label className="platformColor-lbl" htmlFor="roleList">
                  {t("pleaseSelectOrganisationRole")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitOrgEdit}
          />
        </CreateOrganisationDialogContainer>
      </Dialog>

      <Dialog
        visible={viewOrganisationVisible}
        modal
        header={t("organisationInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewOrganisationVisible(false);
          setSingularOrg({});
        }}
      >
        <ViewOrganisationDialogContainer>
          <form>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="organisationName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularOrg?.name}
                />
                <label className="platformColor" htmlFor="organisationName">
                  {t("organisationName")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="email"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularOrg?.email}
                />
                <label className="platformColor" htmlFor="email">
                  {t("organisationEmail")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="web"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularOrg?.web}
                />
                <label className="platformColor" htmlFor="email">
                  {t("organisationWeb")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="registrationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    singularOrg?.registrationTime
                  )}
                />
                <label className="platformColor" htmlFor="registrationTime">
                  {t("organisationregistrationTime")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="lastModificationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    singularOrg?.lastModificationTime
                  )}
                />
                <label className="platformColor" htmlFor="lastModificationTime">
                  {t("organisationlastModificationTime")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("organisationGeoLocation")}</p>

                <p
                  id="address"
                  className="p-inputtext-sm w-full view-container"
                >
                  {singularOrg?.address}
                  {", "}
                  {singularOrg?.city}
                  {", "}
                  {singularOrg?.country}
                </p>
                {singularOrg?.country ? (
                  <img
                    id="country"
                    className="p-inputtext-sm w-full country-flag-view-container"
                    src={
                      "/assets/countryflagspng250px/" +
                      singularOrg?.country?.toLowerCase() +
                      ".png"
                    }
                    alt={singularOrg?.country.toLowerCase() + ".png"}
                    style={{ width: "60px", height: "40px" }}
                  ></img>
                ) : (
                  <p>{t("noCountryInfoAvailable")}</p>
                )}
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("organisationRoles")}</p>
                <div className="roles-div">
                  {singularOrg?.roles?.map((i) => (
                    <>
                      <Chip
                        label={i["name"]}
                        className="role-chip"
                        // style={{ backgroundColor: "rgb(249, 163, 101)",marginRight:"0.7rem", marginBottom: "0.7rem" }}
                        style={{ backgroundColor: "#00a3ff", marginRight:"0.7rem", marginBottom: "0.7rem" }}
                      />
                      
                    </>
                  ))}
                </div>
              </div>
            </div>
          </form>
        </ViewOrganisationDialogContainer>
      </Dialog>

      <Dialog
        visible={addUserToOrganisationVisible}
        modal
        header={t("createAnUser")}
        className="p-fluid dialog-size"
        onHide={() => {
          setAddUserToOrganisationVisible(false);
          setOrgRoles([]);
          setAssignUserToOrgOid(0);
        }}
      >
        <CreateUserDialogContainer>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="email"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="email">
                  {t("pleaseEnterUserEmail")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="firstName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={userData.firstName}
                  onChange={(e) =>
                    setUserData({ ...userData, firstName: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="firstName">
                  {t("pleaseEnterUserFirstname")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="lastName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={userData.lastName}
                  onChange={(e) =>
                    setUserData({ ...userData, lastName: e.target.value })
                  }
                />
                <label className="platformColor-lbl" htmlFor="lastName">
                  {t("pleaseEnterUserLastname")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitUserCreation}
          />
        </CreateUserDialogContainer>
      </Dialog>

      <Dialog
        visible={removeUserFromOrganisationVisible}
        modal
        header={t("removeAnUser")}
        className="p-fluid dialog-size"
        onHide={() => {
          setRemoveUserFromOrganisationVisible(false);
          setUsersOfOrg([]);

        }}
      >
        <CreateUserDialogContainer>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label w-full">
                <MultiSelect
                  id="organisationOpt"
                  className="w-full input-container removal-ms"
                  // optionValue="id"
                  // value={tbRemoved.user}
                  placeholder=" "
                  value={selectedUsers}
                  options={usersOfOrg}
                  showSelectAll={true}
                  display="chip"
                  appendTo="self"
                  onChange={(e) => {
                    setTbRemoved({
                      ...tbRemoved,
                      user: e.value.map((user) => user?.id),
                      // user: e.value.id,
                    });

                  }}
                  itemTemplate={(option) =>
                    option ? (
                      <div>
                        {option.firstname} {option.lastname} ({option.email})
                      </div>
                    ) : null
                  }
                  selectedItemTemplate={(option) =>
                    option ? (
                      <div>
                        {option.firstname} {option.lastname}
                      </div>
                    ) : null
                  }
                />

                <label className="platformColor-lbl" htmlFor="organisationOpt">
                  {t("pleaseSelectUsersToRemoveFromOrganisation")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitUserRemoval}
          />
        </CreateUserDialogContainer>
      </Dialog>

      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}

      />
    </OrganisationsContainer>
  );
};

export default withTranslation()(Organisations);
