import React, { useState, useEffect } from 'react';
import { CTHomeContainer } from "./assets/CTHomeContainer.style";

const CTHome = () => {

  return (
    <>
      <CTHomeContainer>
        {/* <div className='new-line'>
            Hello page
        </div>
        <div className='new-line'>          
            {helloText.message}
        </div> */}
        {/* <div className='new-line'>          
          <iframe width="1062"
                height="600"
                src= "https://circthread.com/"
                title="Circthread" > 
          </iframe>        
         </div> */}
         <div className='ThreeContainer'>
          {/* <ModelViewer/> */}
          {/* <DotGlobe></DotGlobe> */}
         </div>


      </CTHomeContainer>

    </>
  );
};

export default CTHome;
