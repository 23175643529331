import styled from "@emotion/styled";
export const OrganisationsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align: left;
 

  .description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }

  .backToHomeBtn {
    background-color: #fff;
    color: #116182;
  }
`;
// .view-button {
//   background-color: #f1812f;
// }
export const OrganisationAddContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //gap: 1rem;
  //background-color: #fff;
  //border-radius: 6px;
  flex-shrink: 0;
  //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #595959;
  width: 100%;
  align-items: flex-start;
  padding: 0rem;
  text-align: left;

  .view-button {
    background-color: #382c7c;
    border-radius: 6px;
    border-style: solid;
    border-color: #382c7c;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }

  .view-button-disable {
    cursor: default !important;
  }

  .join-button {
    background-color: #116182;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 13px;
  }

  .text-container * {
    font-size: 13px;
  }

  .colored-text1 {
    color: #116182;
  }
  .colored-text2 {
    color: #f1812f;
  }
`;
export const ViewOrganisationDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 0 1rem 0 1rem;

  .country-flag-view-container{
    border-radius: 2px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .input-container {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;

    .platformColor {
      color: #00a3ff;
    }
    .platformColor-roles {
      color: #00a3ff;
    }
    .chip-title {
      color: #00a3ff;
      font-size: 12px;
    }
    .roles-div {
      margin-right: 1rem;
    }
  }
`;
export const CreateOrganisationDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
  padding: 0 1rem 0 1rem;

  .platformColor-lbl {
    color: #00a3ff;
  }

  .input-container {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 0rem;

    .platformColor {
      color: #00a3ff;
    }
    .platformColor-roles {
      color: #f72685;
    }
  }

  .submit-button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }

  .p-datascroller-list > li {
    border: none !important;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #00a3ff !important;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: #00a3ff !important;
  }

  .data-scrollItem-container {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #f4f4f4;
    border-radius: 6px;

    .left-part {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
    }
  }
`;
