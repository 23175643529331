import apios from "../common/apios";

class PubService {
  /* Services methods */
  getRegisteredServices(){
    return apios.get(
      "pub/getRegisteredServices",
    );
  }
  getRegisteredServiceById(sid){
    return apios.get("pub/getRegisteredServiceById", { 
      params: {
        id : sid
      },
      },
    );
  }
}
export default new PubService();
