import styled from "@emotion/styled";
export const PrivacyPolicyContainer = styled.div`
  max-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  color: #00a3ff;
  margin-left: 3.75rem;

  p{
  font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }
  .description {
    font-size: 15px;
    line-height: 20px;
    // font-weight: 800;
  }

  .backToHomeBtn {
    background-color: #fff;
    color: #00a3ff;
  }
`;