import apios from "../common/apios";
// import authHeader from "../common/auth-header";
import { TOKEN_USER,isLoggedIn,isCTAdmin } from "../common/token-check";


// END POINTLER DEĞİŞECEK

class AuthService {
  // register(signupData) {
  //   return apios.post("auth/register", {
  //     signupData,
  //   });
  // }

  verifyEmail(email, secCode) {
    return apios.get("auth/verifyEmail", {
      params: {
        username: email,
        secCode: secCode,
        // domain --> eklenecek
      },
    });
  }
  login(loginData){
    return apios.post("auth/login",{
      username:loginData.username,
      password:loginData.password
    })
    .then((response) => {
   

      if (response.data.token) {
        localStorage.setItem(
          TOKEN_USER,
          JSON.stringify(response.data)
        );
      }
      return response.data;
    });

  }

  resendVerifyEmail(username, responseCaptcha, domain) {
    return apios.post("auth/mailResendRequest", {
      username,
      responseCaptcha,
      domain,
    });
  }

  forgotPassword(username, responseCaptcha, domain) {
    return apios.post("auth/passwordResetRequest", {
      username,
      responseCaptcha,
      domain,
    });
  }

  setNewPassword(username, seccode, password, responseCaptcha, domain) {
    return apios.post("auth/setNewPassword", {
      username,
      password,
      seccode,
      responseCaptcha,
      domain,
    });
  }
  changePassword(username,oldPassword , newPassword) {
    return apios.post("auth/changePassword", {
      username,
      oldPassword,
      newPassword,
    });
  }

  logout() {
    localStorage.removeItem(TOKEN_USER);
  }

}

export default new AuthService();
