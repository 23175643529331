import apios from "../common/apios";
import authHeader from "./auth-header";

class AdmService{
    getUsers() {
        return apios.get("adm/users", {
          headers: authHeader(),
        });
      }
}
export default new AdmService();

