import React from "react";
import {
  isCTAdmin,
  isCTManufacturer,
} from "../functionalities/common/token-check";
import SubMenu from "./SubMenu";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as CgIcons from "react-icons/cg";
import * as HiDocIcons from "react-icons/hi";
import * as Fa6Icons from "react-icons/fa6";

const LeftBar = () => {
  const SidebarDataIcons = [
    {
      visible: isCTAdmin() || !isCTAdmin(),
      // title: <Trans i18nKey={"homepage"} />,
      path: "/landing",
      icon: <AiIcons.AiFillHome />,
    },
    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"organisations"} />,
      path: "/allOrganisations",
      icon: <CgIcons.CgOrganisation />,
    },

    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"manageRoles"} />,
      path: "/manageRoles",
      icon: <Fa6Icons.FaLockOpen />,
    },
    {
      visible: !isCTAdmin(),
      // title: <Trans i18nKey={"services"} />,
      path: "/allServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"manageServices"} />,
      path: "/manageServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"providers"} />,
      path: "/allProviders",
      icon: <IoIcons.IoIosCloudDownload />,
    },
    {
      // title: <Trans i18nKey={"searchOpts"} />,
      icon: <FiIcons.FiSearch />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          // title: <Trans i18nKey={"searchProductmodels"} />,
          path: "/searchProductModels",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
        {
          // title: <Trans i18nKey={"searchDPPs"} />,
          path: "/searchDPPs",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
        {
          // title: <Trans i18nKey={"searchProductmodels"} />,
          path: "/searchProductModels",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
      ],
    },

    {
      visible: !isCTAdmin() && isCTManufacturer(),
      // title: <Trans i18nKey={"models"} />,
      path: "/allProductModels",
      icon: <IoIcons.IoIosBarcode />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      // title: <Trans i18nKey={"myProfile"} />,
      path: "/EditProfile",
      icon: <Fa6Icons.FaUserGear />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      // title: <Trans i18nKey={"logout"} />,
      path: "/",
      isLogout: true,
      icon: <IoIcons.IoIosLogOut style={{ transform: "rotate(180deg)" }} />,
    },
  ];
  return (
    <div
      style={{
        backgroundColor: "#382c7c",
        width: "60px",
        minHeight: "calc(100vh - 178px)",
        color: "#fff",
      }}
    >
      {SidebarDataIcons.map((item, index) => {
        return <SubMenu item={item} key={index} />;
      })}
    </div>
  );
};

export default LeftBar;
