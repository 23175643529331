import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "primeicons/primeicons.css";
// import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";

import "primereact/resources/primereact.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";


const root = ReactDOM.createRoot(document.getElementById('root'));

// const container = document.getElementById("root");
// const root = createRoot(container);
root.render(
  // <Provider store={store}>

        <App />
   // <React.StrictMode>

  //      </React.StrictMode>
  //   <App/>
  // </Provider>

);

