import React, { useEffect, useRef, useState } from "react";
import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
} from "../functionalities/common/token-check";
import i18n from "../i18n";
import { withTranslation, Trans } from "react-i18next";
import { Avatar } from "primereact/avatar";
import { Menubar } from "primereact/menubar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { RadioButton } from "primereact/radiobutton";
import { NavbarContainer } from "./assets/NavbarContainer.style";
import { logout } from "../functionalities/actions/auth/authActions";
import EventBus from "../functionalities/common/eventBus";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
// import SysService from "../services/sys.service";
// import PubService from "../services/pub.service";
import { languages } from "../data/test-data";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as CgIcons from "react-icons/cg";
import * as HiDocIcons from "react-icons/hi";
import * as MdIcons from "react-icons/md";
import * as Fa6Icons from "react-icons/fa6";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavIcon,
  SidebarNav,
  SidebarWrap,
  SidebarNavAlt,
} from "./assets/SidebarContainer.style";
import SubMenu from "./SubMenu";
// import { SidebarData } from "../data/sidebar-data";
import { useNavigate } from "react-router-dom";
import AuthService from "../functionalities/services/auth.service";
import NSidebar from "./NSidebar";


const Navbar = ({ t }) => {

  const sidebarRef = useRef(null);
  const [languagePreferencesVisible, setLanguagePreferencesVisible] =
    useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const [countryList, setCountryList] = useState([]);
  const [aboutPageLink, setAboutPageLink] = useState("");
  const [languagePreference, setLanguagePreference] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [chipHover, setChipHover] = useState(false);
  const [chosenChip, setChosenChip] = useState(0);
  // const [postLogin, setPostLogin] = useState(false);
  // const [menuItems, setMenuItems] = useState([]);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebar(false); // Close sidebar if clicked outside
    }
  };

  useEffect(() => {
    if (sidebar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebar]);

  // if (user) {
  //   const decodedJwt = jwt_decode(user.token);
  //   user.fullname = decodedJwt.firstName + " " + decodedJwt.lastName;
  //   user.laName = decodedJwt.laName;
  // }

  const handleLanguageChange = (selectedLanguage) => {
    i18n.changeLanguage(selectedLanguage);
    setLanguage(selectedLanguage);
    localStorage.setItem("i18nextLng", selectedLanguage); // Dil tercihini localStorage'e kaydet
  };

  useEffect(() => {
    if (!language) {
      // Dil tercihi yapılmamışsa, varsayılan dilinizi seçebilirsiniz.
      const defaultLanguage = "en"; // Varsayılan dil
      handleLanguageChange(defaultLanguage);
    }
  }, [language]);

  // useEffect(() => {
  //   // setPostLogin(isLoggedIn())
  //   // window.location.reload()
  //   if (isLoggedIn()) {
  //     setPostLogin(true);

  //     // window.location.reload()
  //   }
  //   isLoggedIn() === true
  //     ? setMenuItems(menuItemsLogin)
  //     : setMenuItems(menuItemsNoLogin);
  // }, [isLoggedIn()]);

  const selectedLanguageV2 = () => {
    switch (language) {
      case "en-US" || "en" || "EN" || "en-GB":
        return "English";
      case "tr":
        return "Türkçe";
      default:
        return "English";
    }
  };

  const selectedLanguage = () => {
    switch (language) {
      case "en-US" || "en" || "EN" || "en-GB":
        return <div className="flex align-items-center">{"English"}</div>;
      case "tr":
        return (
          <div className="flex align-items-center" style={{ height: "16px" }}>
            {"Türkçe"}
          </div>
        );

      // case "fr":
      //   return (
      //     <div className="flex align-items-center" style={{ height: "16px" }}>
      //       {"Français"}
      //     </div>
      //   );
      // case "de":
      //   return (
      //     <div className="flex align-items-center" style={{ height: "16px" }}>
      //       {"Deutsch"}
      //     </div>
      //   );
      // case "hu":
      //   return (
      //     <div className="flex align-items-center" style={{ height: "16px" }}>
      //       {"Magyar"}
      //     </div>
      //   );
      // case "it":
      //   return (
      //     <div className="flex align-items-center" style={{ height: "16px" }}>
      //       {"Italiano"}
      //     </div>
      //   );
      // case "pt":
      //   return (
      //     <div className="flex align-items-center" style={{ height: "16px" }}>
      //       {"Português"}
      //     </div>
      //   );
      // case "es":
      //   return (
      //     <div className="flex align-items-center" style={{ height: "16px" }}>
      //       {"Español"}
      //     </div>
      //   );
      default:
        return (
          <div className="flex align-items-center" style={{ height: "16px" }}>
            {"English"}
          </div>
        );
    }
  };
  const SidebarDataIcons = [

    {
      visible: isCTAdmin() || !isCTAdmin(),
      // title: <Trans i18nKey={"homepage"} />,
      path: "/landing",
      icon: <AiIcons.AiFillHome />,
    },
    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"organisations"} />,
      path: "/allOrganisations",
      icon: <CgIcons.CgOrganisation />,
    },

    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"manageRoles"} />,
      path: "/manageRoles",
      icon: <Fa6Icons.FaLockOpen />,
    },
    {
      visible: !isCTAdmin(),
      // title: <Trans i18nKey={"services"} />,
      path: "/allServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"manageServices"} />,
      path: "/manageServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      // title: <Trans i18nKey={"providers"} />,
      path: "/allProviders",
      icon: <IoIcons.IoIosCloudDownload />,
    },
    {
      // title: <Trans i18nKey={"searchOpts"} />,
      icon: <FiIcons.FiSearch />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          // title: <Trans i18nKey={"searchProductmodels"} />,
          path: "/searchProductModels",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
        {
          // title: <Trans i18nKey={"searchDPPs"} />,
          path: "/searchDPPs",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
        {
          // title: <Trans i18nKey={"searchProductmodels"} />,
          path: "/searchProductModels",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
      ],
    },

    {
      visible: !isCTAdmin() && isCTManufacturer(),
      // title: <Trans i18nKey={"models"} />,
      path: "/allProductModels",
      icon: <IoIcons.IoIosBarcode />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      // title: <Trans i18nKey={"myProfile"} />,
      path: "/EditProfile",
      icon: <Fa6Icons.FaUserGear />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      // title: <Trans i18nKey={"logout"} />,
      path: "/",
      isLogout: true,
      icon: <IoIcons.IoIosLogOut style={{ transform: "rotate(180deg)" }} />,
    },

  ];
  const SidebarData = [
    {
      visible: isCTAdmin() || !isCTAdmin(),
      title: <Trans i18nKey={"homepage"} />,
      path: "/landing",
      icon: <AiIcons.AiFillHome />,
    },
    // {
    //   visible: isCTAdmin() || !isCTAdmin(),
    //   title: <Trans i18nKey={"connection"} />,
    //   // path: "/support",
    //   icon: <IoIcons.IoMdHelpCircle />,
    //   iconClosed: <RiIcons.RiArrowDownSFill />,
    //   iconOpened: <RiIcons.RiArrowUpSFill />,
    //   subNav: [
    //     {
    //         title: <Trans i18nKey={"login"} />,
    //         path: "/login",
    //         icon: <IoIcons.IoIosLogIn   />,
    //     },
    //     {
    //         title: <Trans i18nKey={"register"} />,
    //         path: "/register",
    //         icon: <Fa6Icons.FaWpforms/>,
    //     },
    //     {
    //       title: <Trans i18nKey={"logout"} />,
    //       path: "/",
    //       icon: <IoIcons.IoIosLogOut style={{ transform: "rotate(180deg)" }} />,
    //     },
    //   ],
    // },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"organisations"} />,
      path: "/allOrganisations",
      icon: <CgIcons.CgOrganisation />,
    },
    // {
    //     title:  <Trans i18nKey={"users"} />,
    //     path: "/allUsersPublic",
    //     icon: <FaIcons.FaUserCog  />,

    // },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"manageRoles"} />,
      path: "/manageRoles",
      icon: <Fa6Icons.FaLockOpen />,
    },
    {
      visible: !isCTAdmin(),
      title: <Trans i18nKey={"services"} />,
      path: "/allServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"manageServices"} />,
      path: "/manageServices",
      icon: <FaIcons.FaPhone />,
    },
    {
      visible: isCTAdmin(),
      title: <Trans i18nKey={"providers"} />,
      path: "/allProviders",
      icon: <IoIcons.IoIosCloudDownload />,
    },
    {
      title: <Trans i18nKey={"searchOpts"} />,
      icon: <FiIcons.FiSearch />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          title: <Trans i18nKey={"searchProductmodels"} />,
          path: "/searchProductModels",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
        {
          title: <Trans i18nKey={"searchDPPs"} />,
          path: "/searchDPPs",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
        {
          title: <Trans i18nKey={"searchProductmodels"} />,
          path: "/searchProductModels",
          icon: <HiDocIcons.HiDocumentSearch />,
        },
      ],
    },

    {
      visible: !isCTAdmin() && isCTManufacturer(),
      title: <Trans i18nKey={"models"} />,
      path: "/allProductModels",
      icon: <IoIcons.IoIosBarcode />,
      // iconClosed: <RiIcons.RiArrowDownSFill />,
      // iconOpened: <RiIcons.RiArrowUpSFill />,
      // subNav: [
      //   {
      //     title: <Trans i18nKey={"productmodels"} />,
      //     path: "/allProductModels",
      //     icon: <HiDocIcons.HiDocumentSearch />,
      //   },
      //   {
      //     title: <Trans i18nKey={"dpps"} />,
      //     path: "/allDpps",
      //     icon: <IoIcons.IoIosBarcode />,
      //   },
      // ],
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      title: <Trans i18nKey={"myProfile"} />,
      path: "/EditProfile",
      icon: <Fa6Icons.FaUserGear />,
    },
    {
      visible: isCTAdmin() || !isCTAdmin(),
      title: <Trans i18nKey={"logout"} />,
      path: "/",
      isLogout: true,
      icon: <IoIcons.IoIosLogOut style={{ transform: "rotate(180deg)" }} />,
    },
    // {
    //     title:  <Trans i18nKey={"etc"} />,
    //     // path: "/support",
    //     icon: <IoIcons.IoMdHelpCircle />,
    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,
    //     subNav: [
    //         {
    //             title: <Trans i18nKey={"privacyPolicy"} />,
    //             path: "/PrivacyPolicy",
    //             icon: <MdIcons.MdPolicy  />,
    //         },
    //         {
    //             title: <Trans i18nKey={"termsAndConditions"} />,
    //             path: "/TermsAndConditions",
    //             icon: <RiIcons.RiContractUpDownLine />,
    //         },
    //     ],
    // },
  ];
  const menuItemsLogin = [
    {
      visible: isLoggedIn(),
      // label: t("search"),
      label: (
        <Chip
          label={t("search")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 1)) ? "#f72685" : "#00a3ff"}}
          icon="pi pi-search"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(1);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      url: "/search",
    },
    {
      visible: isLoggedIn(), //!isAdmin(),
      label: (
        <Chip
          label={t("home")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 2)) ? "#382c7c" : "#00a3ff"  }}
          icon="pi pi-home"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(2);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      url: "/landing",
    },
    {
      visible: isLoggedIn(),
      label: (
        <Chip
          label={t("about")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 4)) ?  "rgb(249, 163, 101)" :"#00a3ff"  }}
          icon="pi pi-question-circle"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(4);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      command: (e) => {
        window.open("https://circthread.com/", "_blank");
      },
    },
    {
      visible: isLoggedIn(),
      label: (
        <Chip
          label={
            <span className="navbarLang">
              {language !== null && selectedLanguageV2()}
            </span>
          }
          className="mr-2 mb-2"
          style={{color:((chosenChip === 5)) ? "#fff" : "#000", backgroundColor: ((chosenChip === 5)) ?  "#00a3ff" :"#dee2e6"  }}
          icon="pi pi-globe"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(5);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      items: [
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">English</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("en");
            setLanguage("en");
          },
        },
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">Türkçe</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("tr");
            setLanguage("tr");
          },
        },
      ],
    },
  ];

  const menuItemsNoLogin = [
    {
      visible: true,
      label: (
        <Chip
          label={t("search")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 1)) ?  "#f72685" : "#00a3ff"}}
          icon="pi pi-search"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(1);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      url: "/search",
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("services")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 7)) ?  "#f72685" : "#00a3ff"}}
          icon="pi pi-briefcase"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(7);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      url: "/allServices",
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("about")}
          className="mr-2 mb-2"
          style={{ color: "#fff", backgroundColor: ((chosenChip === 4)) ? "rgb(249, 163, 101)" : "#00a3ff" }}
          icon="pi pi-question-circle"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(4);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      command: (e) => {
        window.open("https://circthread.com/", "_blank");
      },
    },
    {
      visible: true,
      label: (
        <Chip
          label={t("login")}
          className="mr-2 mb-2"
          style={{color: "#fff", backgroundColor: ((chosenChip === 5)) ?  "#20d4a4" : "#00a3ff" }}
          icon="pi pi-sign-in"
          onMouseEnter={()=>{
            // setChipHover(true);
            setChosenChip(5);
          }}
          onMouseLeave={()=>{
            // setChipHover(false);
            setChosenChip(0);
          }}
        />
      ),
      url: "/login",
    },
    {
      visible: true,
      label: (
        <Chip
          label={
            <span className="navbarLang">
              {language !== null && selectedLanguageV2()}
            </span>
          }
          className="mr-2 mb-2"
          // style={{color: "#fff",}}
          // style={{ backgroundColor: "rgb(249, 163, 101)" }}
          style={{color:((chosenChip === 6)) ? "#fff" : "#000", backgroundColor: ((chosenChip === 6)) ?  "#00a3ff" :"#dee2e6"  }}
          icon="pi pi-globe"
          onMouseEnter={()=>{
            setChosenChip(6);
          }}
          onMouseLeave={()=>{
            setChosenChip(0);
          }}
        />
      ),
      items: [
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">English</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("en");
            setLanguage("en");
          },
        },
        {
          label: (
            <div className="grid">
              <div className="col-6  flex align-items-center">{""}</div>
              <div className="col-6 flex align-items-center justify-content-end">
                <span className="navbarLang">Türkçe</span>
              </div>
            </div>
          ),
          command: () => {
            i18n.changeLanguage("tr");
            setLanguage("tr");
          },
        },
      ],
    },
    // {
    //   visible: true,
    //   label: (
    //     <span className="navbarLang">
    //       {language !== null && selectedLanguage()}
    //     </span>
    //   ),
    //   items: [
    //     // {
    //     //   label: (
    //     //     <div className="grid">
    //     //       <div className="col-6  flex align-items-center">{""}</div>
    //     //       <div className="col-6 flex align-items-center justify-content-end">
    //     //         <span className="navbarLang">Deutsch</span>
    //     //       </div>
    //     //     </div>
    //     //   ),
    //     //   command: () => {
    //     //     i18n.changeLanguage("de");
    //     //     setLanguage("de");
    //     //   },
    //     // },
    //     {
    //       label: (
    //         <div className="grid">
    //           <div className="col-6  flex align-items-center">{""}</div>
    //           <div className="col-6 flex align-items-center justify-content-end">
    //             <span className="navbarLang">English</span>
    //           </div>
    //         </div>
    //       ),
    //       command: () => {
    //         i18n.changeLanguage("en");
    //         setLanguage("en");
    //       },
    //     },
    //     // {
    //     //   label: (
    //     //     <div className="grid">
    //     //       <div className="col-6  flex align-items-center">{""}</div>
    //     //       <div className="col-6 flex align-items-center justify-content-end">
    //     //         <span className="navbarLang">Español</span>
    //     //       </div>
    //     //     </div>
    //     //   ),
    //     //   command: () => {
    //     //     i18n.changeLanguage("es");
    //     //     setLanguage("es");
    //     //   },
    //     // },
    //     // {
    //     //   label: (
    //     //     <div className="grid">
    //     //       <div className="col-6  flex align-items-center">{""}</div>
    //     //       <div className="col-6 flex align-items-center justify-content-end">
    //     //         <span className="navbarLang">Français</span>
    //     //       </div>
    //     //     </div>
    //     //   ),
    //     //   command: () => {
    //     //     i18n.changeLanguage("fr");
    //     //     setLanguage("fr");
    //     //   },
    //     // },
    //     // {
    //     //   label: (
    //     //     <div className="grid">
    //     //       <div className="col-6  flex align-items-center">{""}</div>
    //     //       <div className="col-6 flex align-items-center justify-content-end">
    //     //         <span className="navbarLang">Italiano</span>
    //     //       </div>
    //     //     </div>
    //     //   ),
    //     //   command: () => {
    //     //     i18n.changeLanguage("it");
    //     //     setLanguage("it");
    //     //   },
    //     // },
    //     // {
    //     //   label: (
    //     //     <div className="grid">
    //     //       <div className="col-6  flex align-items-center">{""}</div>
    //     //       <div className="col-6 flex align-items-center justify-content-end">
    //     //         <span className="navbarLang">Magyar</span>
    //     //       </div>
    //     //     </div>
    //     //   ),
    //     //   command: () => {
    //     //     i18n.changeLanguage("hu");
    //     //     setLanguage("hu");
    //     //   },
    //     // },
    //     // {
    //     //   label: (
    //     //     <div className="grid">
    //     //       <div className="col-6  flex align-items-center">{""}</div>
    //     //       <div className="col-6 flex align-items-center justify-content-end">
    //     //         <span className="navbarLang">Português</span>
    //     //       </div>
    //     //     </div>
    //     //   ),
    //     //   command: () => {
    //     //     i18n.changeLanguage("pt");
    //     //     setLanguage("pt");
    //     //   },
    //     // },
    //     {
    //       label: (
    //         <div className="grid">
    //           <div className="col-6  flex align-items-center">{""}</div>
    //           <div className="col-6 flex align-items-center justify-content-end">
    //             <span className="navbarLang">Türkçe</span>
    //           </div>
    //         </div>
    //       ),
    //       command: () => {
    //         i18n.changeLanguage("tr");
    //         setLanguage("tr");
    //       },
    //     },
    //   ],
    // },
    //   {
    //     visible: true,
    //     label: (
    //         <IconContext.Provider value={{ color: "#fff" }}>
    //             <Nav onClick={showSidebar}>
    //                 <NavIcon to="#" >
    //                     <FaIcons.FaBars
    //                         onClick={showSidebar}
    //                     />
    //                 </NavIcon>
    //             </Nav>
    //             <SidebarNav sidebar={sidebar}>
    //                 <SidebarWrap>
    //                     <NavIcon to="#">
    //                         <AiIcons.AiOutlineClose
    //                             onClick={showSidebar}
    //                         />
    //                     </NavIcon>
    //                     {SidebarData.map((item, index) => {
    //                         return (
    //                             <SubMenu
    //                                 item={item}
    //                                 key={index}
    //                             />
    //                         );
    //                     })}
    //                 </SidebarWrap>
    //             </SidebarNav>
    //         </IconContext.Provider>
    //       ),
    //     //indicate nacicon button
    // },
  ];

  const start = () => {
    return (
      <div
        // style={{maxWidth: "200px", alignContent: "center"}}
      >
        <IconContext.Provider value={{ color: "#fff" }}>

          <Nav onClick={showSidebar}>
            <NavIcon to="#">
              <img src="/assets/img/ctloading.gif" alt="ct_logo" onClick={showSidebar} style={{maxHeight: "45px", maxWidth: "45px"}} />
              {/* <i class="pi pi-arrow-right" style={{color:"#fff"}}></i> */}
            </NavIcon>
          </Nav>
       

          <SidebarNav ref={sidebarRef} sidebar={sidebar}>
            <SidebarWrap>
              <NavIcon to="#">
              
                <img src="/assets/img/ct_logo.png" alt="ct_logo" onClick={showSidebar} style={{maxHeight: "50px", maxWidth: "160px"}} />

              </NavIcon>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </IconContext.Provider>
       
      </div>

    );
  };

  const end = () => {
    return (
      <>
        {/* {userAvatar ? (
          <Avatar image={userAvatar} size="large" shape="circle" />
        ) : (
          <Avatar image={""} size="large" shape="circle" />
        )} */}
        {/* <Avatar image={""} size="large" shape="circle" /> */}
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
          }}
          onClick={() =>
            isLoggedIn()
              ? (window.location.href = "/")
              : (window.location.href = "/")
          }
        >
          <img
            // src={"/assets/img/ctloading.gif"}
            src={"/assets/img/Logo-Circthread.png"}
            alt=""
            style={{
              // marginRight: "19px",
              height: "75px",
              cursor: "pointer",
              maxWidth: "200px",
              objectFit: "contain",
            }}
          />
        </button>
      </>
    );
  };

  const handleLanguagePrefences = () => {
    i18n.changeLanguage(languagePreference);
    setLanguagePreferencesVisible(false);
  };

  return (
    <NavbarContainer>
      {/* <div className="a">aaaa */}
      {isLoggedIn() ? (
        <Menubar
          // model={menuItems.filter((item) => item.visible)}
          model={menuItemsLogin.filter((item) => item.visible)}

          start={start}
          end={end}
          id="menuBar"
        />
      ) : (
        // <Menubar model={menuItems} end={end} id="menuBar" />
        <Menubar model={menuItemsNoLogin} end={end} id="menuBar" />

      )}
      {/* </div> */}
    </NavbarContainer>
  );
};

export default withTranslation()(Navbar);
