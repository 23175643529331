import { TermsAndConditionsContainer } from './assets/TermsAndConditionsContainer.style'
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';

const TermsAndConditions = ({t}) => {
    return (
      <TermsAndConditionsContainer>
          <p>{t("ct_tnc")}</p>

          <div className='description'>
              {t("notFoundText")}
          </div>
          {/* <NavLink to={"/"}>
          <Button label={t("goToHomePage")} className='backToHomeBtn'/>
          </NavLink> */}
      </TermsAndConditionsContainer>
    )
  }
  
  export default withTranslation()(TermsAndConditions);