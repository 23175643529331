import styled from "@emotion/styled";

export const CTHomeContainer = styled.div`
  .new-line {
    white-space: pre-line;
  }
  ThreeContainer {
    max-width: 120px;
    max-height: 100px;
  }
`;
