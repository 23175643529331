import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { withTranslation, Trans } from 'react-i18next';


const NSidebar = ({t}) => {

{/* <Sidebar>
  <Menu>
    <SubMenu label="Charts">
      <MenuItem> Pie charts </MenuItem>
      <MenuItem> Line charts </MenuItem>
    </SubMenu>
    <MenuItem> Documentation </MenuItem>
    <MenuItem> Calendar </MenuItem>
  </Menu>
</Sidebar>; */}
    <div style={{ display: "flex", height: "100vh" }}>
    <Sidebar className="app">
      <Menu>
        <MenuItem className="menu1">
          <h2>QUICKPAY</h2>
        </MenuItem>
        <MenuItem> Dashboard </MenuItem>
        <MenuItem> Invoices </MenuItem>
        <MenuItem> Charts </MenuItem>
        <MenuItem> Wallets </MenuItem>
        <MenuItem> Transactions </MenuItem>
        <MenuItem> Settings </MenuItem>
        <MenuItem> Logout </MenuItem>
      </Menu>
    </Sidebar>
    <h1>WELCOME TO QUICKPAY</h1>
  </div>
}

export default withTranslation()(NSidebar);