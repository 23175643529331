import {
  EditProfileContainer,
  ChangePasswordContainer,
  ChangePasswordDialogContainer,
} from "./assets/EditProfileContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { useEffect, useState } from "react";
import {
  ToastContainer,
  toast,
  Slide,
  Zoom,
  Flip,
  Bounce,
} from "react-toastify";
import WaitDialog from "../components/WaitDialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import AuthService from "../functionalities/services/auth.service";
import { giveUsername } from "../functionalities/common/token-check";

const EditProfile = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [password, setPassword] = useState({});
  const [username, setUsername] = useState("");

  const submitPasswordChange = () => {
    setLoading(true);
 
    if (
      password.oldPassword === "" ||
      password.oldPassword === null ||
      password.oldPassword === undefined ||
      password.newPassword === "" ||
      password.newPassword === null ||
      password.newPassword === undefined ||
      password.newPassword2 === "" ||
      password.newPassword2 === null ||
      password.newPassword2 === undefined
    ) {
      toast.warning(<Trans i18nKey="warnMandatory" />);
      setLoading(false);
      return;
    }
    if (password.newPassword === "" && password.newPassword.length < 8) {
      toast.warning(<Trans i18nKey="warnSetNewPassword2" />);
      setLoading(false);
      return;
    }

    if (password.newPassword !== password.newPassword2) {
      toast.warning(<Trans i18nKey="warnSetNewPassword3" />);
      setLoading(false);
      return;
    }
  
    AuthService.changePassword(
      username,
      password.oldPassword,
      password.newPassword
    ).then(
      (response) => {
     

        if (response.status === 200) {
          toast.success(<Trans i18nKey="successChangedPassword" />);
          setLoading(false);
          setChangePasswordVisible(false);
        } else {
       
          toast.error(response.status, "failChangedPassword");
          setLoading(false);
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
    
        toast.error(errorMessage, "failChangedPassword");

        setLoading(false);
      }
    );
    setPassword({});
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => setUsername(giveUsername()), []);
  return (
    <EditProfileContainer>
      <h2>{t("myProfile")}</h2>
      <p className="changePasswordDesc">{t("changePasswordDesc")}</p>
      <ChangePasswordContainer>
        <p>
          {t("myUsername")} : {username}
        </p>
        <Button
          className="password-button"
          label={t("changePassword")}
          onClick={() => {
            setChangePasswordVisible(true);
            // setUsername(giveUsername());
          }}
        />
      </ChangePasswordContainer>
      <Dialog
        visible={changePasswordVisible}
        modal
        header={t("changePassword")}
        className="p-fluid dialog-size"
        onHide={() => {
          setChangePasswordVisible(false);
          setPassword({});
        }}
      >
        <ChangePasswordDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
              // letterSpacing: "0.5px",
            }}
          >
            {/* {t("changePassword")} */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <Password
                  id="oldPassword"
                  type="text"
                  toggleMask
                  feedback={false}
                  className="p-inputtext-sm w-full"
                  value={password.oldPassword}
                  onChange={(e) =>
                    setPassword({ ...password, oldPassword: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="oldPassword">
                  {t("pleaseEnterOldPassword")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <Password
                  id="newPassword"
                  type="text"
                  className="w-full p-inputtext-sm"
                  toggleMask
                  promptLabel={t("passwordpromptLabel")}
                  weakLabel={t("passwordWeakLabel")}
                  mediumLabel={t("passwordMediumLabel")}
                  strongLabel={t("passwordStrongLabel")}
                  value={password.newPassword}
                  onChange={(e) =>
                    setPassword({ ...password, newPassword: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="newPassword">
                  {t("pleaseEnterNewPassword")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <Password
                  id="newPassword2"
                  type="text"
                  className="w-full p-inputtext-sm"
                  toggleMask
                  feedback={false}
                  value={password.newPassword2}
                  onChange={(e) =>
                    setPassword({ ...password, newPassword2: e.target.value })
                  }
                />
                <label className="platformColor" htmlFor="newPassword2">
                  {t("pleaseEnterNewPassword2")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitPasswordChange}
          />
        </ChangePasswordDialogContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </EditProfileContainer>
  );
};
export default withTranslation()(EditProfile);
