import {
  ServicesContainer,
  CreateServicesDialogContainer,
  ViewServicesDialogContainer,
} from "./assets/ServicesContainer.style";
import { ServicesCardsSection } from "./assets/ServicesCardsSection";
import {
  OrganisationsContainer,
  OrganisationAddContainer,
} from "./assets/OrganisationsContainer.style";
import { ToggleButton } from 'primereact/togglebutton';
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { servicesList, serviceTypesList,statusTypesList } from "../data/test-data";
import PubService from "../functionalities/services/pub.service";
import WaitDialog from "../components/WaitDialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import ServicesCards from "../components/ServicesCards";
// import Swal from "sweetalert2";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce  } from "react-toastify";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { format } from "date-fns";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";

const emptyServiceData = {
  serviceName: "",
  // serviceContactEmail: "",
  serviceType: 0,
  serviceEndPoint: "",
  serviceOrganisationName: "",
};

const EditServices = ({ t }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState(emptyServiceData);
  const [createServiceVisible, setCreateServiceVisible] = useState(false);
  const [viewServiceVisible, setViewServiceVisible] = useState(false);
  const [editServiceVisible, setEditServiceVisible] = useState(false);
  const [singularService, setSingularService] = useState({});

  const submitServiceCreation = () => {
    setLoading(true);

    if (
      serviceData.serviceName === "" ||
      // serviceData.serviceContactEmail === "" ||
      serviceData.serviceOrganisationName === "" ||
      serviceData.serviceType === 0 ||
      serviceData.serviceEndPoint === ""
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    // if (
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
    //     serviceData.serviceContactEmail
    //   )
    // ) {
    //   toast.warning(<Trans i18nKey="warnEmailFormat" />);
    //   setLoading(false);
    //   return;
    // // }
    // if (serviceData.serviceType === 1 && serviceData.serviceEndPoint === "") {
    //   toast.warning(<Trans i18nKey="warnIframeServiceLinkMissing" />);
    //   setLoading(false);
    //   return;
    // }

    const registeredServiceDTO = {
      registeredServiceDTO: {
        name: serviceData.serviceName,
        organisationName: serviceData.serviceOrganisationName,
        type: getServiceTypeName(serviceData.serviceType),
        endpoint: serviceData.serviceEndPoint,
      },
    };
 
    SysService.addRegisteredService(registeredServiceDTO).then(
      (response) => {
     
        toast.success(<Trans i18nKey={"successServiceCreation"} />);
        if(response.status === 200){
          SysService.getRegisteredServices().then(
            (response) => {
            
              setServices(response.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
      
           
            }
          );
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

      
        toast.error(error, <Trans i18nKey={"failServiceCreation"} />,{
            style:{
            background: '#EBEBEB',
            color:"#000"
          }
        })
      }
    );

    setLoading(false);
    setCreateServiceVisible(false);
    setServiceData(emptyServiceData);
    return
  };
  const submitServiceEdit = () => {
 
    setLoading(true);
    if (
      singularService.name === "" ||
      singularService.organisationName === "" ||
      singularService.type === "" ||
      singularService.endpoint === ""
    ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    const registeredServiceDTO = {
      registeredServiceDTO: {
        id: singularService.id,
        name: singularService.name,
        organisationName: singularService.organisationName,
        type: singularService.type,
        endpoint: singularService.endpoint,
        status: singularService.status
      },
    };
   
    SysService.updateRegisteredService(registeredServiceDTO).then(
      (response) => {
       
        if(response.status === 200){
          toast.success(<Trans i18nKey={"successServiceUpdate"} />,{
            style:{
              background: '#EBEBEB',
              color:"#000"
            }
          });
          SysService.getRegisteredServices().then(
            (response) => {
            
              setServices(response.data);
            },
            (error) => {
              const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
      
        
            }
          );
        }
        else{
          toast.error(response.status, <Trans i18nKey={"failServiceUpdate"} />,{
            style:{
              background: '#EBEBEB',
              color:"#000"
            }
          })
        }
      },
      (error) => {  
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

     
        toast.error(error.response.status + " " + error.response.data.responseMessage + " " +error.name)
      }
    );

    setLoading(false);
    setSingularService({});
    setEditServiceVisible(false);
    return;
  }
  const getServiceInfo = (id) => {
    SysService.getRegisteredServiceById(id).then(
      (response) => {
     
        setSingularService(response.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
      
      }
    );
  };
  const getServiceTypeName = (serviceCode) => {
    for (let i = 0; i < serviceTypesList.length; i++) {
      if (serviceTypesList[i]["serviceTypeCode"] === serviceCode) {
        return serviceTypesList[i]["serviceTypeName"];
      }
    }
  };
  const registrationTimeTemplate = (data) => {
    if (
      data?.registrationDate !== undefined &&
      data?.registrationDate !== null &&
      data?.registrationDate !== ""
    ) {
      const d = new Date(data?.registrationDate);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  const lastmodTimeTemplate = (data) => {
    if (
      data?.lastModificationTime !== undefined &&
      data?.lastModificationTime !== null &&
      data?.lastModificationTime !== ""
    ) {
      const d = new Date(data?.registrationTime);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  const actionTemplateStatus = (data) => {
    return (
      // t("STATUS_" + data.status)
      <Chip
        label={t("STATUS_" + data.status)}
        className="mr-2 mb-2"
        style={{ backgroundColor: "#382c7c" ,color:"#fff"}}
        // style={{ backgroundColor: "#f72685" }}
      />
    );
  };
  const statusInfo = (statData) => {
    return t("STATUS_" + statData);
  }

  const actionTemplate = (data) => {
    if (data.status === 5) {
      return (
        <div>
          {/* <Button
            icon="pi pi-search"
            tooltip={t("details")}
            className="p-button-rounded p-button-text"
            // onClick={() => handleUpdateOrganisation(data)}
            id="buttonFontWeight"
          /> */}
        </div>
      );
    } else {
      return (
        <div>
          <Button
            icon="pi pi-pencil"
            tooltip={t("edit")}
            className="p-button-rounded p-button-text service-edit-button"
            onClick={() => {
              setEditServiceVisible(true);
              getServiceInfo(data.id);
            }}
            id="buttonFontWeight"
          />
          <Button
            icon="pi pi-exclamation-circle"
            tooltip={t("inquire")}
            className="p-button-rounded p-button-text"
            onClick={() => {
              setViewServiceVisible(true);
              getServiceInfo(data.id);
            }}
            id="buttonFontWeight"
          />
        </div>
      );
    }
  };
  const typeListTemplate = (data) => {
    return (
      <>
        <div>
          {/* if type 1 or 2 change bckgrnd clr */}
          {(() => {
            switch (data?.type) {
              case "IFRAME":
                return (
                  <Chip
                    label={data?.type}
                    className="mr-2 mb-2"
                    style={{ backgroundColor: "#72bfbf",color: "#fff" }}
                  />
                );
              case "API":
                return (
                  <Chip
                    label={data?.type}
                    className="mr-2 mb-2"
                    // style={{ backgroundColor: "#20d4a4" }}
                    style={{ backgroundColor: "#83bf4f", color: "#fff" }}

                  />
                );
              default:
                return "";
            }
          })()}
        </div>
      </>
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    SysService.getRegisteredServices().then(
      (response) => {
      
        setServices(response.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

      
      }
    );
  }, []);
  return (
    <ServicesContainer>
      <h2>{t("servicesPage")}</h2>
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createAService")}
          onClick={() => setCreateServiceVisible(true)}
        />
      </OrganisationAddContainer>
      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={services}
              dataKey="id"
              // stripedRows
              responsiveLayout="scroll"
              emptyMessage={t("noServicesFound")}
              // header={renderHeader1}
              paginator
              rows={10}
              // filters={filters1}
              // filterDisplay="menu"
              loading={loading}
              // globalFilterFields={["serviceName", "serviceContact", "status"]}
            >
              <Column
                field="name"
                header={t("serviceName")}
                // sortable
              ></Column>
              <Column
                field="organisationName"
                header={t("serviceOrganisationName")}
                // sortable
              ></Column>
              {/* <Column
                field="serviceContact"
                header={t("serviceContact")}
                // sortable
              ></Column> */}
              <Column
                field={typeListTemplate}
                header={t("serviceType")}
              ></Column>
              <Column field="endpoint" header={t("serviceEndpoint")}></Column>
              <Column
                body={actionTemplateStatus}
                header={t("status")}
                // sortable
              ></Column>
              {/* <Column
                field={registrationTimeTemplate}
                header={t("registrationDateAndTime")}
              ></Column>
              <Column
                body={lastmodTimeTemplate}
                header={t("lastModDateAndTime")}
              ></Column> */}
              <Column
                header={t("serviceActions")}
                style={{ textAlign: "center", width: "8em" }}
                body={actionTemplate}
              />
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={createServiceVisible}
        modal
        header={t("createAService")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateServiceVisible(false);
          setServiceData(emptyServiceData);
        }}
      >
        <CreateServicesDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
              // letterSpacing: "0.5px",
            }}
          >
            {/* {t("createAService")} */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData?.serviceName}
                  onChange={
                    // (e) => handleOrgCreation("organisationName", e.target.value)
                    (e) =>
                      setServiceData({
                        ...serviceData,
                        serviceName: e.target.value,
                      })
                  }
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("pleaseEnterServiceName")} (*)
                </label>
              </div>
              {/* <div className="p-float-label input-container">
                <InputText
                  id="email"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData.serviceContactEmail}
                  onChange={(e) => setServiceData({ ...serviceData, serviceContactEmail: e.value })}
                />
                <label className="platformColor" htmlFor="email">
                  {t("pleaseEnterServiceContactEmail")}
                </label>
              </div> */}
              <div className="p-float-label input-container">
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData.serviceOrganisationName}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceOrganisationName: e.target.value,
                    })
                  }
                />
                <label
                  className="platformColor"
                  htmlFor="serviceOrganisationName"
                >
                  {t("pleaseEnterServiceOrganisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceEndpoint "
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={serviceData.serviceEndPoint}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceEndPoint: e.target.value,
                    })
                  }
                />
                <label className="platformColor" htmlFor="serviceEndpoint">
                  {t("pleaseEnterServiceEndpoint")} (*)
                </label>
              </div>
              <div className="p-float-label w-full input-container">
                <Dropdown
                  id="serviceType"
                  className="w-full p-inputtext-sm"
                  value={serviceData.serviceType}
                  options={serviceTypesList}
                  onChange={(e) =>
                    setServiceData({
                      ...serviceData,
                      serviceType: e.target.value,
                    })
                  }
                  optionLabel="serviceTypeName"
                  optionValue="serviceTypeCode"
                  appendTo="self"
                />
                <label className="platformColor" htmlFor="serviceType">
                  {t("pleaseSelectServiceType")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitServiceCreation}
          />
        </CreateServicesDialogContainer>
      </Dialog>
      <Dialog
        visible={editServiceVisible}
        modal
        header={t("serviceInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setEditServiceVisible(false);
          setServiceData(emptyServiceData);
        }}
      >
        <CreateServicesDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              // letterSpacing: "0.5px",
              marginBottom: "0.5rem",
            }}
          >
            {/* {t("serviceInfo")} */}
          </div>
          <form>
            {/* //onSubmit={handleSubmit} */}
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.name}
                  onChange={(e) => setSingularService({...singularService, name:e.target.value})}
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("serviceName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.organisationName}
                  onChange={(e) => setSingularService({...singularService, organisationName:e.target.value})}
                />
                <label className="platformColor" htmlFor="serviceOrganisationName">
                  {t("serviceOrganisationName")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="endpoint"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  value={singularService?.endpoint}
                  onChange={(e) => setSingularService({...singularService, endpoint:e.target.value})}
                />
                <label className="platformColor" htmlFor="endpoint">
                  {t("serviceEndpoint")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
                <Dropdown
                  id="serviceStatus"
                  className="w-full p-inputtext-sm"
                  optionLabel="statusTypeName"
                  optionValue="statusTypeCode"
                  placeholder={statusInfo(singularService?.status)}
                  value={singularService?.status}
                  options={statusTypesList}
                  // editable
                  onChange={
                    (e) => setSingularService({...singularService, status:e.target.value})
                  }
                />
        
                <label className="platformColor" htmlFor="serviceStatus">
                  {t("serviceStatus")} (*)
                </label>
              </div>
              <div className="p-float-label input-container">
              <Dropdown
                  id="serviceType"
                  className="w-full p-inputtext-sm"
                  optionLabel="serviceTypeName"
                  optionValue="serviceTypeName"
                  placeholder={singularService?.type}
                  value={singularService?.type}
                  options={serviceTypesList}
                  // editable
                  onChange={
                    (e) => setSingularService({...singularService, type:e.target.value})
                  }
                />
                <label className="platformColor" htmlFor="type">
                  {t("serviceType")} (*)
                </label>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitServiceEdit}
          />
        </CreateServicesDialogContainer>
      </Dialog>
      <Dialog
        visible={viewServiceVisible}
        modal
        header={t("serviceInfo")}
        className="p-fluid dialog-size"
        onHide={() => {
          setViewServiceVisible(false);
          // setServiceData(emptyServiceData);
        }}
      >
        <ViewServicesDialogContainer>
          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              // letterSpacing: "0.5px",
              marginBottom: "0.5rem",
            }}
          >
            {/* {t("serviceInfo")} */}
          </div>
          <form>
            {/* //onSubmit={handleSubmit} */}
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.name}
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("serviceName")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="serviceOrganisationName"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.organisationName}
                />
                <label className="platformColor" htmlFor="serviceOrganisationName">
                  {t("serviceOrganisationName")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="endpoint"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={singularService?.endpoint}
                />
                <label className="platformColor" htmlFor="endpoint">
                  {t("serviceEndpoint")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="registrationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    singularService?.registrationTime
                  )}
                />
                <label className="platformColor" htmlFor="registrationTime">
                  {t("serviceregistrationTime")}
                </label>
              </div>
              <div className="p-float-label input-container">
                <InputText
                  id="lastModificationTime"
                  type="text"
                  className="p-inputtext-sm w-full view-container"
                  disabled
                  value={GeneralFunctions.getDateTemplateV2(
                    singularService?.lastModificationTime
                  )}
                />
                <label className="platformColor" htmlFor="lastModificationTime">
                  {t("servicelastModificationTime")}
                </label>
              </div>
              <div className="p-float-label input-container">
                {/* <div className="chip-holder"> */}
                  <p className="chip-title">{t("serviceStatus")}</p>
                  {actionTemplateStatus(singularService)}
                {/* </div> */}
                {/* <label className="" htmlFor="status">
                  {t("serviceStatus")}
                </label> */}
              </div>
              <div className="p-float-label input-container">
                <p className="chip-title">{t("serviceType")}</p>

                {typeListTemplate(singularService)}

                {/* <label className="platformColor" htmlFor="type">
                  {t("serviceType")}
                </label> */}
              </div>
            </div>
          </form>
        </ViewServicesDialogContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition= {Slide}
        // toastStyle={{ backgroundColor: "crimson" }}
      />
    </ServicesContainer>
  );
};

export default withTranslation()(EditServices);
