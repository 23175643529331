import { DppsContainer } from './assets/DppsContainer.style'
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';

const Dpps = ({t}) => {
    return (
      <DppsContainer>
          <p>Dpps</p>

          <div className='description'>
              {t("notFoundText")}
          </div>
          <NavLink to={"/"}>
          <Button label={t("goToHomePage")} className='backToHomeBtn'/>
          </NavLink>
      </DppsContainer>
    )
  }
  
  export default withTranslation()(Dpps);