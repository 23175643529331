import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RegisterContainer } from "./assets/RegisterContainer.style";
import { Trans, withTranslation } from "react-i18next";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce  } from "react-toastify";
import { login } from "../functionalities/actions/auth/authActions";
import { useNavigate } from "react-router-dom";
import i18n from "../i18n";
import WaitDialog from "../components/WaitDialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

import { register } from "../functionalities/actions/auth/authActions";



const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "*",
};

const emptySignupRequest = {
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    // responseCaptcha: "",
    privacyPolicy: false,
  };


  const Register = ({ t }) => {
    const getCurrentLang = () => {
      return localStorage.getItem("i18nextLng");
    };
    const domain = window && window.location && window.location.hostname;
    // const message = useSelector((state) => state?.message?.message);
  
    const [loading, setLoading] = useState(false);
    // const recaptcha = useRef(null);
    // const [recaptchaKey, setRecaptchaKey] = useState(0);
  
    // const dispatch = useDispatch();
    const [registerData, setRegisterData] = useState(emptySignupRequest);

    const [isRegister, setIsRegister] = useState({
      success: false,
      email: "",
    });

  
    // useEffect(() => {
    //   setRecaptchaKey((prevKey) => prevKey + 1);
    //   // if (recaptcha) recaptcha?.current.reset();
    // }, [i18n.language]);
 

  
    const handleChange = (field, value) => {
        setRegisterData((prevState) => ({
          ...prevState,
          [field]: value,
        }));
    };
  
    const handleSubmit = async () => {
      setLoading(true);
      if (
        registerData.username === "" ||
        registerData.password === "" ||
        registerData.firstName === "" ||
        registerData.lastName === "" ||
        registerData.password === ""
      ) {
        toast.warning(<Trans i18nKey="warnMandatory" />);
        setLoading(false);
        return;
      }
  
      // if (
      //   registerData.responseCaptcha === "" ||
      //   registerData.responseCaptcha === null
      // ) {
      //   toast.warning(<Trans i18nKey="warnCaptcha" />);
      //   setRegisterData({ ...registerData, responseCaptcha: "" });
      //   return;
      // }
  
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(registerData.username)
      ) {
        toast.warning(<Trans i18nKey="warnEmailFormat" />);
        setLoading(false);
        return;
      }
  
      if (registerData.password.length < 8) {
        toast.warning(<Trans i18nKey="warnSetNewPassword2" />);
        setLoading(false);
        return;
      }
  
      if (registerData.password !== registerData.confirmPassword) {
        toast.warning(<Trans i18nKey="warnSetNewPassword3" />);
        setLoading(false);
        return;
      }
  
      if (!registerData.privacyPolicy) {
        toast.warning(<Trans i18nKey="warnPrivacyPolicy" />);
        setLoading(false);
        return;
      }
  
    //   recaptcha?.current.reset();
    //   let token = await recaptcha.current.executeAsync();
  
    //   if (token) {
    //     const signupData = {
    //       ...registerData,
    //       responseCaptcha: token,
    //       organisationData:
    //         selectedOrCreatedOrg !== "" ? selectedOrCreatedOrg : null,
    //       domain,
    //     };
  
    //     dispatch(register(signupData))
    //       .then(() => {
    //         setLoading(false);
    //         recaptcha.current.reset();
    //         setIsRegister({ email: registerData.username, success: true });
    //         setRegisterData(emptySignupRequest);
    //       })
    //       .catch((error) => {
    //         const errorMessage =
    //           (error.response &&
    //             error.response.data &&
    //             error.response.data.message) ||
    //           error.message ||
    //           error.timeoutErrorMessage ||
    //           error.toString();
  
    //         if (errorMessage === "1006") {
    //           setIsRegister({ ...isRegister, success: false });
    //           Swal.fire({
    //             title: t(errorMessage),
    //             icon: "error",
    //             timer: 2000,
    //             showConfirmButton: false,
    //           });
    //           setRegisterData(emptySignupRequest);
    //         } else if (errorMessage === "1006a") {
    //           setTransferDataDialog(true);
    //         }
  
    //         recaptcha.current.reset();
  
    //         setLoading(false);
    //       });
    //   } else {
    //     setLoading(true);
    //   }
    };
       
    return (
      <RegisterContainer>
        {!isRegister?.success ? (
          <>
            <div className="header-container">
              <div className="welcome-text">
                  <>{t("welcomeTo")}</>
              </div>
              <div className="register-text">{t("registerTxt1")}</div>
            </div>
            <div className="input-container">
              <div className="p-float-label textinput-container" id="email">
                <InputText
                  id="email"
                  type="text"
                  value={registerData.email}
                  className="w-full p-inputtext-sm block"
                  onChange={(e) => handleChange("email", e.target.value)}
                />
  
                <label className="platformColor" htmlFor="email">
                  {t("email")} (*)
                </label>
              </div>
              <div className="p-float-label textinput-container" id="username">
                <InputText
                  id="username"
                  type="text"
                  value={registerData.username}
                  className="w-full p-inputtext-sm block"
                  onChange={(e) => handleChange("username", e.target.value)}
                />
  
                <label className="platformColor" htmlFor="username">
                  {t("userName")} (*)
                </label>
              </div>
              <div className="p-float-label textinput-container" id="firstName">
                <InputText
                  id="firstName"
                  type="text"
                  value={registerData.firstName}
                  className="w-full p-inputtext-sm block"
                  onChange={(e) => handleChange("firstName", e.target.value)}
                />
                <label className="platformColor" htmlFor="firstName">
                  {t("firstName")} (*)
                </label>
              </div>
              <div className="p-float-label textinput-container" id="lastName">
                <InputText
                  id="lastName"
                  type="text"
                  value={registerData.lastName}
                  className="w-full p-inputtext-sm block"
                  onChange={(e) => handleChange("lastName", e.target.value)}
                />
                <label className="platformColor" htmlFor="lastName">
                  {t("lastName")} (*)
                </label>
              </div>
              <div className="p-float-label passport-container">
                <Password
                  id="password"
                  type="text"
                  className="w-full p-inputtext-sm"
                  value={registerData.password}
                  toggleMask
                  promptLabel={t("passwordpromptLabel")}
                  weakLabel={t("passwordWeakLabel")}
                  mediumLabel={t("passwordMediumLabel")}
                  strongLabel={t("passwordStrongLabel")}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                <label className="platformColor" htmlFor="password">
                  {t("password")} (*)
                </label>
              </div>
              <div className="p-float-label passport-container">
                <Password
                  id="password2"
                  type="text"
                  value={registerData.confirmPassword}
                  className="w-full p-inputtext-sm"
                  feedback={false}
                  toggleMask
                  onChange={(e) =>
                    handleChange("confirmPassword", e.target.value)
                  }
                />
                <label className="platformColor" htmlFor="password2">
                  {t("repeatPassword")} (*)
                </label>
              </div>
            </div>
  


  
            <div className="w-full p-inputtext-sm block mb-0" style={{textAlign :"left"}}>
              <Checkbox
                onChange={(e) => handleChange("privacyPolicy", e.checked)}
                checked={registerData.privacyPolicy}
              ></Checkbox>{" "}
              <Trans
                i18nKey="registerTxt2"
                components={{
                    TermsAndConditions: (
                    <a
                      className="platformColorTertiary"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="/TermsAndConditions"
                    >
                      Terms of Use
                    </a>
                  ),
                  PrivacyPolicy: (
                    <a
                      className="platformColorTertiary"
                      target="_blank"
                      rel="noreferrer noopener"
                      href="/PrivacyPolicy"
                    >
                      Privacy Policy
                    </a>
                  ),
                }}
              />
            </div>
            {/* <div className="recaptcha">
              <ReCAPTCHA
                ref={recaptcha}
                key={recaptchaKey}
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={(e) => handleChange("responseCaptcha", e, "r")}
                hl={i18n.language}
                size="invisible"
              />
            </div> */}
  
            <Button
              label={t("register")}
              className="register-button"
              type="submit"
              onClick={handleSubmit}
            />
            <div className="text-center mb-2">
              {t("registerTxt3")}{" "}
              <a className="login-text" href="/login">
                {t("login")}
              </a>
            </div>
            <div className="logo">
              <span>{t("footerCopyright")}
              <img src="/assets/img/ekodengeinc_logo.jpg" alt="ekodenge_logo" style={{width:"50px",height:"50px"}} />

              </span>
            </div>
          </>
        ) : (
          <div className="header-container">
            <div className="welcome-text">{t("register")}</div>
            <div className="registered-message">
              {/* <div>{t(message)}</div> */}
            </div>
            <div className="registered-navigate mt-4">
              <div className="text-center mb-2">
                <Trans
                  i18nKey="backToLogin"
                  components={{
                    login: (
                      <a rel="noreferrer noopener" href="/login">
                        Login
                      </a>
                    ),
                    giris: (
                      <a rel="noreferrer noopener" href="/login">
                        Giriş
                      </a>
                    ),
                  }}
                />
              </div>
              <div className="text-center mb-2">
                {t("txtLogin3")}{" "}
                <a className="login-text" href="/resendEmail">
                  {t("resendEmail")}
                </a>
              </div>
            </div>
          </div>
        )}
  
       
        <ToastContainer
          autoClose={2200}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          theme="dark"
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover={true}
          transition={Slide}
        />
  
        <WaitDialog loading={loading} lang={t} />
      </RegisterContainer>
    );
  };

export default withTranslation()(Register);
