import styled from "@emotion/styled";

export const RegisterContainer = styled.div`

  max-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #116182;

  .header-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .welcome-text {
      font-weight: 600;
      color: #116182;
      font-size: 20px;
    }
    .register-text {
      font-weight: 400;
      color: #21ace3;
      font-size: 15px;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .textinput-container {
      .p-component {
        width: 100%;
      }
    }
    .passport-container {
      .p-component {
        width: 100%;
      }
    }
  }

  .terms-and-condition {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .recaptcha {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toggle-button-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .toggle-container {
      background-color: #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
      border-radius: 6px;
    }
  }

  .register-button {
    background-color: #21ace3;
    color: #fff;
    width: 100%;
  }

  .logo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
  }

  .organisation-container {
    border: 1px solid #3db7e7;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .search-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .search-button {
        background-color: #116182;
        color: #fff;
      }
    }

    .organisation-list {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      max-height: 415.68px;
      overflow-y: auto;

      .org-item {
        display: flex;
        background: #f4f4f4;
        border-radius: 6px;
        padding: 1rem;
        width: 100%;

        align-items: center;
        gap: 0.3rem;

        .img-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 2;
          .org-img {
            width: 50px;
            height: 40px;
            object-fit: contain;
          }
        }
        .content-container {
          display: flex;
          flex-direction: column;
          gap: 0.1rem;
          align-items: start;
          justify-content: start;
          flex: 9;
          text-align: left;
          .org-name {
            color: #116182;
            font-weight: 550;
          }
          .country {
            color: #3db7e7;
          }
          .others {
            color: #595959;
            .website {
              color: #3db7e7;
            }
          }
        }

        .action-container {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;

          .join-button {
            background-color: #3db7e7;
          }
        }
      }
    }

    .create-organisation {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .create-org-text {
        text-align: left;
        color: #f1812f;
      }
      .create-organisation-button {
        width: 100%;
        color: #f1812f;
      }
    }
  }

  .login-text {
    text-decoration: underline;
    color: #116182;
  }

  .registered-message {
    color: #21ace3;
    .registerd-user {
      font-weight: 700;
    }
  }

  
`;
