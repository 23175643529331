import { useEffect, useState } from "react";
import { OrganisationAddContainer } from "./assets/OrganisationsContainer.style";
import { UsersContainer, CreateUserDialogContainer } from './assets/UsersContainer.style'
import { Button } from 'primereact/button';
import { NavLink } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { Dropdown } from "primereact/dropdown";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { MultiSelect } from "primereact/multiselect";
import { ConfirmDialog } from "primereact/confirmdialog";
import WaitDialog from "../components/WaitDialog";

import { withTranslation, Trans } from "react-i18next";
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce  } from "react-toastify";
import { format } from 'date-fns';
import AdmService from "../functionalities/services/adm.services";

import {usersList,userRolesList} from "../data/test-data"

const emptyUserData = {
  userName: "",
  password: "",
  firstName: "",
  lastName: "",
  email: "",
  roleList: [],
  registrationDate: "",
};

const Users = ({t}) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [createUserVisible,setCreateUserVisible] = useState(false);
  const [userData,setUserData] = useState(emptyUserData);

  const getUsersDummy = () => {
    // setLoading(true);
  
    setUsers(usersList)
    window.scrollTo(0, 0);
  };
  const submitUserCreation = () => {
 
    setLoading(true);

    if (userData.userName === "" || userData.email === "" || userData.firstName === "" || userData.lastName === "" || userData.roleList === 0 ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.email)
    ) {
      toast.warning(<Trans i18nKey="warnEmailFormat" />);
      setLoading(false);
      return;
    }
  };

  const getUsers = () => {
    setLoading(true);
  
    AdmService.getUsers().then(
      (response) => {
        // setOrganisations(response.data);
        setUsers(response.data)
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setContent(
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString()
        );
        setBlockedPanel(true);
  
        toast.error(<Trans i18nKey={content} />);
      }
    );
  
    // initFilters1();
  };
  const roleListTemplate = (data) => {
    return (
      <>
        <div>
          {data?.roleList?.map((ai) => (
            
            <>
              <Chip
                // label={ai.split("__")[0]}
                label={ai}
                className="mr-2 mb-2"
                style={{ backgroundColor: "rgb(249, 163, 101)" }}
              />
            </>
          ))}
        </div>
      </>
    );
  };
  const registrationTimeTemplate = (data) => {
    if (data?.registrationDate !== undefined && data?.registrationDate !== null && data?.registrationDate !== "") {
      const d = new Date(data?.registrationDate);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  
  const approveRejectTimeTemplate = (data) => {
    if (data?.approvalrejectionDate !== undefined && data?.approvalrejectionDate !== null && data?.approvalrejectionDate !== "") {
      const d = new Date(data?.approvalrejectionDate);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };
  const lastTokenTimeTemplate = (data) => {
    if (data?.lastTokenDate !== undefined && data?.lastTokenDate !== null && data?.lastTokenDate !== "") {
      const d = new Date(data?.lastTokenDate);
      return format(d, "dd/MM/yyyy HH:mm");
    }
  };

  const actionTemplateStatus = (data) => {
    return t("STATUS_" + data.status);
  };
  const actionTemplate = (data) => {
    if (data.status === 5) {
      return (
        <div>
          <Button
            icon="pi pi-search"
            tooltip={t("details")}
            className="p-button-rounded p-button-text"
            // onClick={() => handleUpdateOrganisation(data)}
            id="buttonFontWeight"
          />
        </div>
      );
    } else {
      return (
        <div>
          <Button
            icon="pi pi-pencil"
            tooltip={t("edit")}
            className="p-button-rounded p-button-text"
            // onClick={() => handleUpdateOrganisation(data)}
            id="buttonFontWeight"
          />
          <Button
            icon="pi pi-user"
            tooltip={t("organisationAdmins")}
            className="p-button-rounded p-button-text"
            // onClick={() => {
            //   setOrganisationSB(data);
            //   setVisibleManageOrganisationSidebar(true);
            // }}
            id="buttonFontWeight"
          />
        </div>
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
    return (
      <UsersContainer>
          <h2>{t("usersPage")}</h2>

          <OrganisationAddContainer>
            <Button
              className="view-button"
              label={t("createAnUser")}
              onClick={() => setCreateUserVisible(true)}
            />

          </OrganisationAddContainer>

          <div className='description'>
              {t("userslistmsg")}
          </div>
          <BlockUI fullscreen autoZIndex={false}>
          <div className="col-12">
              <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
                <DataTable
                  name="dt"
                  size="small"
                  showGridlines
                  value={users}
                  dataKey="id"
                  stripedRows
                  responsiveLayout="scroll"
                  emptyMessage={t("noUsersFound")}
                  // header={renderHeader1}
                  paginator
                  rows={10}
                  // filters={filters1}
                  // filterDisplay="menu"
                  loading={loading}
                  globalFilterFields={["firstname", "lastname", "username","email", "status"]}
                >
                  <Column
                    field="firstname"
                    header={t("firstName")}
                    // sortable
                  ></Column>
                  <Column
                    field="lastname"
                    header={t("lastName")}
                    // sortable
                  ></Column>
                  <Column
                    field="username"
                    header={t("userName")}
                    // sortable
                  ></Column>
                  <Column
                    field="email"
                    header={t("email")}
                    // sortable
                  ></Column>
                  <Column
                    body={actionTemplateStatus}
                    header={t("status")}
                    // sortable
                  ></Column>
                  <Column
                    field={registrationTimeTemplate}
                    header={t("registrationDateAndTime")}
                  ></Column>
                  <Column
                    field={approveRejectTimeTemplate}
                    header={t("approverejectDateAndTime")}
                  ></Column>
                  <Column
                    field={lastTokenTimeTemplate}
                    header={t("lastTokenDate")}
                  ></Column>
                  <Column
                    field={roleListTemplate}
                    header={t("roleList")}
                  ></Column>
                  {/* <Column
                    header={t("action")}
                    style={{ textAlign: "center", width: "8em" }}
                    body={actionTemplate}
                  /> */}
                </DataTable>
              </div>
            </div>
          </BlockUI>
          
          <NavLink to={"#"}>
            <Button label={<Trans i18nKey={"getUsers"}/> }className='backToHomeBtn' onClick={getUsersDummy}/>
          </NavLink>
          <NavLink to={"/"}>
            <Button label={t("goToHomePage")} className='backToHomeBtn'/>
          </NavLink>
          <Dialog
            visible={createUserVisible}
            modal
            className="p-fluid dialog-size"
            onHide={() => {
              setCreateUserVisible(false);
              setUserData(emptyUserData);
            }}
          >
            <CreateUserDialogContainer>
            <div
              className="px-2 platformColor"
              id="dialogTitle"
              style={{
                fontSize: "16px",
                lineHeight: "18px",
                fontWeight: "700",
                // letterSpacing: "0.5px",
              }}
            >
              {t("createAnUser")}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex align-items-center gap-3 ">
                <div className="p-float-label input-container">
                  <InputText
                    id="userName"
                    type="text"
                    className="p-inputtext-sm w-full"
                    value={userData?.userName}
                    onChange={
                      // (e) => handleOrgCreation("organisationName", e.target.value)
                    (e) => setUserData({ ...userData, userName: e.value })
                    }
                  />
                  <label className="platformColor" htmlFor="userName">
                    {t("pleaseEnterUserUsername")} (*)
                  </label>
                </div>
                <div className="p-float-label input-container">
                  <InputText
                    id="email"
                    type="text"
                    className="p-inputtext-sm w-full"
                    value={userData.email}
                    onChange={(e) =>  setUserData({ ...userData, email: e.value })}
                  />
                  <label className="platformColor" htmlFor="email">
                    {t("pleaseEnterUserEmail")} (*)
                  </label>
                </div>
                <div className="p-float-label input-container">
                  <InputText
                    id="firstName"
                    type="text"
                    className="p-inputtext-sm w-full"
                    value={userData.firstName}
                    onChange={(e) => setUserData({ ...userData, firstName: e.value })}
                  />
                  <label className="platformColor" htmlFor="firstName">
                    {t("pleaseEnterUserFirstname")} (*)
                  </label>
                </div>
                <div className="p-float-label input-container">
                  <InputText
                    id="lastName"
                    type="text"
                    className="p-inputtext-sm w-full"
                    value={userData.lastName}
                    onChange={(e) => setUserData({ ...userData, lastName: e.value })}
                  />
                  <label className="platformColor" htmlFor="lastName">
                    {t("pleaseEnterUserLastname")} (*)
                  </label>
                </div>
                <div className="p-float-label passport-container">
                  <Password
                    id="password"
                    type="text"
                    className="w-full p-inputtext-sm"
                    value={userData.password}
                    toggleMask
                    promptLabel={t("passwordpromptLabel")}
                    weakLabel={t("passwordWeakLabel")}
                    mediumLabel={t("passwordMediumLabel")}
                    strongLabel={t("passwordStrongLabel")}
                    onChange={(e) => setUserData({ ...userData, lastName: e.value })}
                  />
                  <label className="platformColor" htmlFor="password">
                    {t("pleaseEnterUserPassword")}
                  </label>
                </div>
                <div className="p-float-label w-full">
                  <MultiSelect
                    id="roleList"
                    className="w-full p-inputtext-sm"
                    optionLabel="roleName"
                    optionValue="roleName"
                    //maxSelectedLabels={1}
                    showSelectAll={true}
                    value={userData?.roleList}
                    // style={{ height: "44.88px" }}
                    options={userRolesList}
                    onChange={(e) =>
                      setUserData({ ...userData, roleList: e.value })
                    }
                    appendTo="self"
                  />
                  <label className="platformColor" htmlFor="roleList">
                    {t("pleaseSelectUserRole")} (*)
                  </label>
                </div>

              </div>
            </form>
            <Button
              className="submit-button"
              label={t("submit")}
              onClick={submitUserCreation}
            />

            {/* <div className="mb-2" id="dialogTable">
                  <DataScroller
                    value={searchOrganisations}
                    itemTemplate={scrollTableTemplate}
                    emptyMessage={t("noOrganisationFound")}
                    rows={3}
                    inline
                    scrollHeight="300px"
                  />
                </div> */}
          </CreateUserDialogContainer>
          </Dialog>

          <WaitDialog loading={loading} lang={t} />
        <ToastContainer
          autoClose={2200}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          theme="dark"
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover={true}
          transition= {Slide}
          />
      </UsersContainer>
    )
  }
  
  export default withTranslation()(Users);